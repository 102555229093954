import { createEmptyPort } from "./FlightInformation/FlightInformation";
import { Port } from "./FlightInformation/Port"
import { GoodsInfo } from "./GoodsInformation";

/**
 * 配送情報
 */
export interface OrderInfo {
    /**
     * 注文番号
     */
    orderID: string,
    /**
     * ビジネスパートナーID
     */
    businessPartnerId: string,
    /**
     * 外部ID
     */
    externalOrderID?: string,
    /**
     * 注文日
     */
    orderDate: string,
    /**
     * 配送希望日時
     */
    preferredDeliveryDate: string,
    /**
     * 配送希望日時
     */
    preferredDeliveryTime: string,
    /**
     * 出発ポート
     */
    departure: Port,
    /**
     * 到着ポート
     */
    arrival: Port,
    /**
     * 配送物情報
     */
    goods: Array<GoodsInfo>,

    /**
     *　重心位置（東京都実証だけの対応）
     */
    positionCenterGravity?: {
        x: number,
        y: number,
        z: number
    },
    /**
     * カスタム重量
     * goodsに重量が設定されなかった場合
     */
    customWeight?: number,
    /**
     * 配送先情報
     */
    destination: DestinationInfo,
    /**
     * 配送依頼をした団体の情報（セブンとかアインとか）
     */
    requester: RequesterInfo
    /**
     * 配送依頼のステータス
     */
    status: OrderStatus,
    /**
     * 配送物の受け取り状態
     */
    receiveStatus: ReceiveStatus,
    /**
     * 備考
     */
    remarks: string,
    /**
     * 受け取り場所情報
     */
    pickupLocationInfo?: PickupLocationInfo,
    /**
     * 注文情報確認用のレシート画像のファイル名
     */
    receiptFileName?: string
}


export interface PickupLocationInfo {
    /**
     * 受け取り場所のタイプ
     */
    locationType: PickupLocationType
    /**
     * 住所
     */
    address: string,
    /**
     * ラベル(わかりやすい場所：例、xxxの公民館)
     */
    label: string,
    /**
     * GoogleMapのURL
     */
    googleMapURL: string,
    /**
     * 場所の写真のURL1
     */
    siteURL1?: string,
    /**
     * 場所の写真のURL2
     */
    siteURL2?: string,
    /**
     * 場所の写真のURL3
     */
    siteURL3?: string,
    /**
     * 場所の写真のURL4
     */
    siteURL4?: string,
}


/**
 * 受け取り場所
 */
export const PickupLocationType = {
    ArrivalPort: "ArrivalPort",
    Home: "Home"
} as const;
export type PickupLocationType = typeof PickupLocationType[keyof typeof PickupLocationType];


/**
 * メンバータイプ。
 * Guestは非会員
 * Memberは会員
 */
export const MemberType = {
    Guest: "Guest",
    Member: "Member"
} as const;
export type MemberType = typeof MemberType[keyof typeof MemberType];

/**
 * オーダーの状況
 */
export const OrderStatus = {
    /**
     * 受付中
     */
    Accepting: "Accepting",
    /**
     * 注文済み
     */
    Order: "Order",
    /**
     * 配送物の集荷待ち
     */
    WaitingForPickup: "WaitingForPickup",
    /**
     * 配送物を集荷して飛行準備中の状態
     */
    InPreparation: "InPreparation",
    /**
     * もうすぐ到着（5分目安）
     */
    ArrivingSoon: "ArrivingSoon",
    /**
     * 飛行中
     */
    InFlight: "InFlight",
    /**
     * 到着
     */
    Arrivaled: "Arrivaled",
    /**
     * 戻りフライト準備中
     */
    ReturnInPreparation: "ReturnInPreparation",
    /**
     * 戻りフライト中
     */
    ReturnInFlight: "ReturnInFlight",
    /**
     * 戻り到着直前
     */
    ReturnArrivingSoon: "ReturnArrivingSoon",
    /**
     * 戻り到着
     */
    ReturnArrivaled: "ReturnArrivaled",
    /**
     * 完了
     */
    Completed: "Completed",
    /**
     * キャンセル
     */
    Canceled: "Canceled",
} as const;
export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 配送物の受領状態
 */
export const ReceiveStatus = {
    /**
     * 配送済み
     */
    Received: "Received",
    /**
     * 荷物搭載済み
     */
    Loaded: "Loaded",
    /**
     * 搭載準備中
     */
    Preparation: "Preparation",
    /**
     * @deprecated
     */
    NotReceived: "NotReceived",
    /**
     * 集荷待ち
     */
    WaitingForPickup: "WaitingForPickup",
    /**
     * キャンセル
     */
    Cancel: "Cancel"

} as const;
export type ReceiveStatus = typeof ReceiveStatus[keyof typeof ReceiveStatus];


/**
 * 送り先情報
 */
export interface DestinationInfo {
    /**
     * 
     */
    type: MemberType

    /**
     * メンバータイプがguestの場合空文字
     * メンバータイプがmemberの場合メンバーIDが入る
     */
    id: string,

    /**
     * 名前
     */
    firstname: string,

    /**
     * 苗字
     */
    lastname: string,

    /**
     * 県
     */
    prefecture: string,
    /**
     * 市住所
     */
    city: string,

    /**
     * 市以降の住所
     */
    address: string,

    /**
     * 郵便番号
     */
    zipcode: string,

    /**
     * 電話番号
     */
    tel: string,
    /**
     * 第2電話番号　（店員用電話番号）
     */
    tel2?: string,
    /**
     * 第3電話番号　（不使用）
     */
    tel3?: string,
}

export function createDefaultDestination(): DestinationInfo {
    return {
        id: "",
        address: "",
        city: "",
        firstname: "",
        lastname: "",
        prefecture: "",
        type: MemberType.Member,
        zipcode: "",
        tel: "",
    };
}



/**
 * 依頼者情報
 */
export interface RequesterInfo {
    /**
     * 
     */
    type: MemberType

    /**
     * メンバータイプがguestの場合空文字
     */
    id: string,

    /**
     * 名
     */
    firstname: string,

    /**
     * 姓
     */
    lastname: string,

    /**
     * 県
     */
    prefecture: string,
    /**
     * 市住所
     */
    city: string,

    /**
     * 市以降の住所
     */
    address: string,

    /**
     * 郵便番号
     */
    zipcode: string,
    /**
     * 電話番号
     */
    tel: string
}



/**
 * 初期データ作成
 * @deprecated
 */
export function createInitData(): OrderInfo {
    const order: OrderInfo = {
        orderID: "",
        businessPartnerId: "",
        orderDate: "",
        preferredDeliveryDate: "",
        preferredDeliveryTime: "",
        remarks: "",
        status: OrderStatus.Accepting,
        goods: new Array<GoodsInfo>(),
        departure: createEmptyPort(),
        arrival: createEmptyPort(),
        receiveStatus: ReceiveStatus.NotReceived,
        requester: {
            id: "",
            address: "",
            city: "",
            firstname: "",
            lastname: "",
            prefecture: "",
            type: MemberType.Member,
            zipcode: "",
            tel: "",
        },
        destination: createDefaultDestination(),
    }
    return order;
}

export function parseOrderInfo(order: any): OrderInfo {
    const result = order as OrderInfo;
    return result;
}