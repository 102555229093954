/**
 * ポート情報
 */
export interface Port {
    /**
     * ポートID
     */
    id: string,
    /** 
    * テナントID
    */
    tenantId: string,
    /**
     * ソートキー
     */
    sortkey: number,
    /**
     * 場所リスト
     */
    spotList?: Spot[],
    /**
     * ポート名
     */
    name: string,
    /**
     * 短いポート名
     */
    shortName: string,
    /**
     * ポートを利用する提携企業
     */
    businessPartnerId: string,
    /**
     * ポートタイプ
     */
    portType: PortType,
    /**
     * ポートを管理する会社
     */
    managementCompany: string,
    /**
     * ポートを管理している連絡先
     */
    portManager: {
        /**
         * 名前
         */
        name: string,
        /**
         * 電話番号
         */
        tel?: string,
        /**
         * メール
         */
        email?: string,
        /**
         * 備考
         */
        remarks?: string,
    }
    /**
     * ポート住所
     */
    address: string,
    /**
     * ポートの緯度
     */
    longitude: string,
    /**
     * ポートの経度
     */
    latitude: string,
    /**
     * 郵便番号
     */
    zipcode: string,
    /**
     * 備考
     */
    remarks?: string,
    /**
     * 県
     */
    prefecture: string,
    /**
     * 市住所
     */
    city: string,
    /**
     * 入力フォーム用のデフォルト住所
     */
    inputDefaultAddress: string,
    /**
     * 入力フォーム用のデフォルト市
     */
    inputDefaultCity: string,
    /**
     * 入力フォーム用のデフォルト県
     */
    inputDefaultPrefecture: string,
    /**
     * 入力フォーム用のデフォルト郵便番号
     */
    inputDefaultZipcode: string,
    /**
     * このポートが到着できる目的地
     */
    destinationPortList: string[],
    /**
     * このポートのGoogleMap上のパス
     */
    gmapPath: string,
    /*
     *環境センサー
     */
    envSensorDeviceID?: string,
    /**
     * レコードを更新した日付
     */
    update: string,
}

/**
 * スポット情報
 */
export interface Spot {
    id: string,
    name: string,
    latitude: string,
    longitude: string,
    remarks?: string,
}

/**
 * ポートタイプ
 */
export const PortType = {
    /**
     * 配送物を送ることができるポート
     */
    sender: "sender",
    /**
     * 配送物を受け取ることができるポート
     */
    receiver: "receiver",
    /**
     * 両方
     */
    both: "both"
} as const;
export type PortType = typeof PortType[keyof typeof PortType];

/**
 * ポート情報の初期化データ
 */
export interface InitPortInfo { 
    tenantId: string, 
    data: Port[] 
}

export interface DeletePortInfo { 
    tenantId: string, 
    Key: {
        businessPartnerId: string,
        id: string
    }[] 
}